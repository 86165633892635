import moment from "moment";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { userServices } from "../../../../apiServices/userServices";
import { toastErro, toastSucesso } from "../../../Swal";

export function ModalRecebimentoMetal({ cliente, handleFetch, handleListaTransacaoDeCliente }) {
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");

  const [atribuicaoMetalData, setAtribuicaoMetalData] = useState({
    cliente: "",
    material: 3,
    quantidade: 0,
    data_hora: moment(new Date()).format("YYYY-MM-DD HH:mm"),
    descricao: "",
  });

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setAtribuicaoMetalData((prevData) => ({
      ...prevData,
      cliente: cliente.ID,
    }));
  }, [cliente]);

  const handleAtribuicaoMetal = async (e) => {
    e.preventDefault();

    const data = {
      ...atribuicaoMetalData,
      token: token,
    };

    const response = await userServices.balanceAdd(data);
    if (response.data.status === "success") {
      toastSucesso("Operação realizada com sucesso!");
      handleFetch();
      handleListaTransacaoDeCliente()
      handleClose()
    } else {
      toastErro("Ocorreu um erro ao realizar a operação!");
    }
  };

  return (
    <>
      <Button disabled={!cliente} onClick={handleOpen}>Lancar recebimento de metal </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lançar recebimento de metal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Cliente: {cliente.NAME}</h5>
          <Alert variant="warning" className="d-flex flex-column">
            <div>
              <strong>Atenção!</strong>{" "}
              <span>
                Para o material Ouro 1000, não será necessário fazer a conversão. O sistema realiza a conversão autoamticamente
                para 18k. Digite a quantidade exata que está em suas mãos.
              </span>
            </div>
            <strong>Conversão: QTD + 33,33% = Saldo inserido na conta do cliente</strong>
          </Alert>
          <Card className="p-2 text-center card-one">
            <Row>
              <Col>
                <Form.Label>Material:</Form.Label>
                <Form.Select
                  value={atribuicaoMetalData.material}
                  onChange={(e) =>
                    setAtribuicaoMetalData((prevData) => ({
                      ...prevData,
                      material: e.target.value,
                    }))
                  }
                  name="metal"
                  id="metal"
                >
                  <option value={""}>---Selecione---</option>
                  <option value={"3"}>Ouro 1000</option>
                  <option value="4">Ouro 18k</option>
                  <option value={"1"}>Prata 950</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  value={atribuicaoMetalData.quantidade}
                  onChange={(e) =>
                    setAtribuicaoMetalData((prevData) => ({
                      ...prevData,
                      quantidade: e.target.value,
                    }))
                  }
                  name="quantidade"
                  id="quantidade"
                  placeholder="Digite a quantidade:"
                ></Form.Control>
              </Col>
              <Col>
                <Form.Label>Data de Recebimento</Form.Label>
                <Form.Control
                  value={atribuicaoMetalData.data_hora}
                  onChange={(e) =>
                    setAtribuicaoMetalData((prevData) => ({
                      ...prevData,
                      data_hora: moment(new Date(e.target.value)).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                    }))
                  }
                  type="datetime-local"
                  name="datetime"
                  id="datetime"
                  className=""
                  placeholder="%"
                ></Form.Control>
              </Col>
            </Row>
            <div className="w-100 mt-3">
              <Form.Label for="detalhamento">Detalhamento</Form.Label>
              <textarea
                value={atribuicaoMetalData.detalhamento}
                onChange={(e) =>
                  setAtribuicaoMetalData((prevData) => ({
                    ...prevData,
                    detalhamento: e.target.value,
                  }))
                }
                name="detalhamento"
                id="detalhamento"
                className="form-control"
              ></textarea>
            </div>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleAtribuicaoMetal} variant="primary">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

