import { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import { Link } from "react-router-dom";
import { AccountContext } from "../../Context/AccountContext/AccountContext";
import DetalhesOurives from "./DetalhesOurives";
import { formatDate } from "@fullcalendar/core/index.js";
import { AlertaBalanco } from "../AlertaBalancoOurives/Alerta";
import { Search } from "lucide-react";
import { OrderContext } from "../../Context/OrderContext";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import Decimal from "decimal.js";

const ListaOurives = ({
  setStatusToFetch,
  returnStatusFetch,
  visualizacao,
}) => {
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    console.log(selectedId);
  }, [selectedId]);
  const { listUser } = useContext(UserContext);
  const { list, accountList } = useContext(AccountContext);
  const [users, setUsers] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [filteredData, setFilteredData] = useState(null);
  const { getOurivesByOrder } = useContext(OrderContext);
  const handleGetOurivesByOrder = async (e) => {
    e.preventDefault();
    if (filteredData.length < 9) {
      toast.dismiss();
      toast.error("Quantidade de caracteres insuficientes.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const payload = {
      order_code: filteredData,
    };

    const response = await getOurivesByOrder(payload);
    if (response !== false) {
      toast.dismiss();

      setSelectedId(response);
      setOverLayDisplay("flex");
    } else {
      toast.dismiss();
      toast.error("Nenhum resultado encontrado", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  const handleOpenModal = (usuario) => {
    setSelectedUser(usuario);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const handleListUser = async () => {
    const payload = {
      permissionType: [14, 15],
    };
    const response = await listUser(payload);
    setUsers(response.data.users);
    returnStatusFetch(false);
  };

  useEffect(() => {
    handleListUser();
    accountList([14, 15]);
  }, []);

  useEffect(() => {
    handleListUser();
    accountList([14, 15]);
  }, [setStatusToFetch]);

  const colorSaldoTotal = (saldo) => {
    if (saldo > 0) {
      return "text-success";
    } else if (saldo < 0) {
      return "text-danger";
    } else {
      return "text-secondary";
    }
  };

  // const detailVariant = {
  //   hidden: { opacity: 0, x: "-100%" },
  //   visible: { opacity: 1, x: "0%" },
  // };

  const [overLayDisplay, setOverLayDisplay] = useState("none");

  const OverLay = ({ children }) => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: overLayDisplay,
          justifyContent: "center",
          alignItems: "center",
          zIndex: "9999",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        {children}
      </div>
    );
  };

  function calculaSaldoOurives(u) {
    const debito = u?.account_debit_credit?.account_debit ? u?.account_debit_credit?.account_debit : 0;
    const credito = u?.account_debit_credit?.account_credit ? u?.account_debit_credit?.account_credit : 0;
    const quebra = u?.total_break ? u?.total_break : 0;
    const prevBalance = u?.account_balance?.prev_balance ? u?.account_balance?.prev_balance : 0 
    const resultado = new Decimal(credito).sub(debito).add(quebra);

    return resultado.toFixed(3);
  }

  return (
    <>
      {/* {list.map((item) => (
        <motion.div
          layoutId={item.user_name}
          onClick={() => setSelectedId(item.user_name)}
        >
          <motion.h5>{item.user_name}</motion.h5>
        </motion.div>
      ))} */}
      <Modal
        size="lg"
        show={Boolean(selectedId)}
        onHide={() => setSelectedId(null)}
      >
        <Modal.Body>
          <Card
            className="p-3 shadow-sm rounded-3 d-flex flex-row justify-content-start"
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              border: "none",
            }}
          >
            <div className="">
              <img
                src={selectedId && selectedId[0]?.FILE}
                style={{
                  width: "33%",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                alt="Item Image"
              />
            </div>
            <div className="w-100">
              <h5 className="fw-bold mb-2" style={{ color: "#343a40" }}>
                Código do Pedido:{" "}
                <span className="fw-normal">
                  {selectedId && selectedId[0]?.CODE}
                </span>
              </h5>
              <h6 className="text-muted mb-2">
                Cliente:{" "}
                <span className="fw-normal">
                  {selectedId && selectedId[0]?.NAME_CLIENT}
                </span>
              </h6>
              <div className="mt-2">
                <strong style={{ color: "#495057" }}>Código da Peça:</strong>
                <span className="ms-2">
                  {selectedId && selectedId[0]?.PART_CODE}
                </span>
              </div>
            </div>
          </Card>

          <div className="d-flex flex-column gap-2 mt-2">
            {selectedId &&
              selectedId.map((filteredItem) => (
                <div
                  key={`${filteredItem.ID_USER}_${filteredItem.PERMISSION_CODE}`}
                  layoutId={`${filteredItem.ID_USER}_${filteredItem.PERMISSION_CODE}`}
                >
                  <Card>
                    <Card.Body>
                      <motion.h5>
                        <strong>Ourives responsável: </strong>
                        {filteredItem.USERNAME}
                      </motion.h5>

                      <div>
                        <strong>Data:</strong>{" "}
                        <span>
                          {new Date(
                            filteredItem.OPERATION_CREATED_AT
                          ).toLocaleDateString()}
                        </span>
                      </div>

                      <div>
                        <strong
                          className="px-1 py-0 rounded-pill"
                          style={{
                            color: "#fff",
                            backgroundColor: filteredItem.OPERATION_COLOR,
                          }}
                        >
                          {filteredItem.OPERATION_NAME}
                        </strong>
                      </div>

                      <div>
                        <strong>Movimentação: </strong>
                        <span>{filteredItem.MOVIMENTATION_NAME}</span>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setSelectedId(null);
              setOverLayDisplay("none");
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertaBalanco list={list} />
      <div className="mb-3 border form-search ">
        <Form className="d-flex w-100" onSubmit={handleGetOurivesByOrder}>
          <Form.Control
            className="w-100"
            placeholder="Pesquisar"
            onChange={(e) => setFilteredData(e.target.value)}
            id="pesquisar"
            name="pesquisar"
          ></Form.Control>
          <Button type="submit" variant="outline">
            <Search />
          </Button>
        </Form>
      </div>
      <div className="d-flex flex-column gap-3">
        {list && list.some((u) => u.permission_type === 14) ? (
          <Card className="card-one">
            <Card.Body>
              <Card.Title>Ourives internos</Card.Title>

              <Table bordered hover className="w-100">
                <thead>
                  <tr className="">
                    <th className="">Ourives</th>
                    <th className="text-center">Saldo Atual</th>
                    <th className="text-center">Quebra Atual</th>
                    <th className="text-center">Débitos</th>
                    <th className="text-center">Cŕeditos</th>
                    <th className="text-center">Último Balanço</th>
                    <th className="text-center">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  <AnimatePresence>
                    {list &&
                      list.map((u, i) => {
                        if (u.permission_type === 14) {
                          return (
                            <motion.tr
                              key={`${u.id_user}_${u.permission_type}`}
                              layoutId={`${u.id_user}_${u.permission_type}`}
                              className="p-2 border rounded "
                            >
                              <td className="" width={450}>
                                {u.user_name}
                              </td>
                              <td
                                className={`text-center ${colorSaldoTotal(
                                  calculaSaldoOurives(u)
                                )}`}
                                width={150}
                              >
                                {visualizacao
                                  ? calculaSaldoOurives(u)
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.total_break
                                    ? u.total_break.toFixed(3)
                                    : "0.000"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.account_debit_credit?.account_debit
                                    ? (-u.account_debit_credit
                                        ?.account_debit).toFixed(3)
                                    : "0.000"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.account_debit_credit?.account_credit
                                    ? u.account_debit_credit?.account_credit.toFixed(
                                        3
                                      )
                                    : "0.000"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.account_balance?.created_at
                                    ? formatDate(u.account_balance?.created_at)
                                    : "Sem balanço registrado"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                <Button
                                  onClick={() => handleOpenModal(u)}
                                  variant="primary"
                                >
                                  <i class="ri-article-line"></i> Detalhes
                                </Button>
                              </td>
                            </motion.tr>
                          );
                        }
                      })}
                  </AnimatePresence>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : null}

        {list && list.some((u) => u.permission_type === 15) ? (
          <Card className="card-one">
            <Card.Body>
              <Card.Title>Ourives externos</Card.Title>
              <Table bordered hover className="w-100">
                <thead>
                  <tr>
                    <th>Ourives</th>
                    <th>Saldo Atual</th>
                    <th>Quebra Atual</th>
                    <th>Débitos</th>
                    <th>Cŕeditos</th>
                    <th>Último Balanço</th>
                    <th>Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  <AnimatePresence>
                    {list &&
                      list.map((u, i) => {
                        if (u.permission_type === 15) {
                          return (
                            <motion.tr
                              id={u.id_user}
                              key={`${u.id_user}_${u.permission_type}`}
                              layoutId={`${u.id_user}_${u.permission_type}`}
                              className="p-2 border rounded "
                            >
                              <td className="" width={450}>
                                {u.user_name}
                              </td>
                              <td
                                className={`text-center ${colorSaldoTotal(
                                  calculaSaldoOurives(u)
                                )}`}
                                width={150}
                              >
                                {visualizacao
                                  ? calculaSaldoOurives(u)
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.total_break
                                    ? (-u.total_break).toFixed(3)
                                    : "0.000"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.account_debit_credit?.account_debit
                                    ? (-u.account_debit_credit
                                        ?.account_debit).toFixed(3)
                                    : "0.000"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.account_debit_credit?.account_credit
                                    ? u.account_debit_credit?.account_credit.toFixed(
                                        3
                                      )
                                    : "0.000"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                {visualizacao
                                  ? u.account_balance?.created_at
                                    ? formatDate(u.account_balance?.created_at)
                                    : "Sem balanço registrado"
                                  : "-"}
                              </td>
                              <td className="text-center" width={150}>
                                <Button
                                  onClick={() => handleOpenModal(u)}
                                  variant="primary"
                                >
                                  <i class="ri-article-line"></i> Detalhes
                                </Button>
                              </td>
                            </motion.tr>
                          );
                        }
                      })}
                  </AnimatePresence>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : null}
      </div>
      <Modal fullscreen show={modalShow} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes de Operações </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetalhesOurives selectedUser={selectedUser} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListaOurives;
