import React, { useEffect, useRef, useState } from "react";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Form,
  Badge,
  ButtonGroup,
  Table,
  Pagination,
  Modal,
  Accordion,
  Image,
  ModalBody,
  Select,
  InputGroup,
  FormGroup,
  FormText,
  Alert,
} from "react-bootstrap";
import { Link, useAsyncError } from "react-router-dom";
import { apiServices } from "../apiServices/ApiServices";
import "sweetalert2/src/sweetalert2.scss";
import { Delete, DeleteIcon, Edit, Plus, Save, Search, Trash2 } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.css";
import Avatar from "../components/Avatar";
import { CarouselCustom } from "../components/Carousel";
import { productServices } from "../apiServices/productServices";
import { userServices } from "../apiServices/userServices";
import { Editor } from "@tinymce/tinymce-react";
import { UpdateModuleImage } from "../components/UpdateModuleImage/UpdateModuleImage";
import { Tooltip } from "react-tooltip";
import { apenasNumerosInteiros } from "../functions/Mask";

export default function ProductManagement() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const token = localStorage.getItem("token");

  const [products, setProducts] = useState([]);
  const [link, setLink] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [productType, setProductType] = useState(0);
  const [productLoading, setProductLoading] = useState(true);
  const [modalProductEditShow, setModalProductEditShow] = useState(false);
  const [modalProductContent, setModalProductContent] = useState({
    id: "",
    name: "",
  });
  const [modalProductShow, setModalProductShow] = useState(false);
  const [modalProduct, setModalProduct] = useState({
    permission: 0,
    product: {},
  });

  const editorRef = useRef(null);
  const [newProductDescription, setNewProductDescription] = useState("");
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(modalProduct.product.info_07);
      setNewProductDescription(modalProduct.product.info_07);
    }
  }, [modalProduct.product.info_07]);

  // Atualiza o estado quando o conteúdo do editor muda
  const onUpdatingModalProductDescription = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setNewProductDescription(content);
    }
  };
  const [modalProductDescription, setModalProductDescription] = useState("");
  const [metalModalProduct, setMetalModalProduct] = useState([]);
  const [metalModule, setMetalModule] = useState([]);

  useEffect(() => {
    console.log(metalModule)
  }, [metalModule])
  const [metalNailing, setMetalNailing] = useState([]);

  const [submitType, setSubmitType] = useState("");

  const [metalTypeNailing, setMetalTypeNailing] = useState([]);
  const [metalAttachment, setMetalAttachment] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [modalImageModule, setModalImageModule] = useState(false);
  const [modalPedido, setModalPedido] = useState(false);

  const [typeNailing, setTypeNailing] = useState([]);
  const [productTypeNailing, setProductTypeNailing] = useState([]);

  const [material, setMaterial] = useState([]);
  const [materialColor, setMaterialColor] = useState([]);
  const [productWeight, setProductWeight] = useState(1);
  const [quantidadeNecessariaState, setQuantidadeNecessariaState] = useState(0);

  const [selectTypeAttachment, setSelectTypeAttachment] = useState([]);

  const [par, setPar] = useState(false);

  const [users, setUsers] = useState([]);
  const [modelista, setModelista] = useState([]);

  const [openNewProduct, setOpenNewProduct] = useState(false);

  const [metalInsert, setMetalInsert] = useState(false);
  const [productId, setProductId] = useState("");

  const [file, setFile] = useState(null);

  const [tramanhoCravacao, setTamanhoCravacao] = useState([]);
  const [productAnexo, setProducAnexo] = useState(null);

  const [typeAnexo, setAnexo] = useState([]);
  const [productStatus, setProductStatus] = useState(null);

  const [resin, setResin] = useState([]);

  const [productPre, setProductPre] = useState({
    product_id: "",
    id_status: "",
    resin_type: "",
    product_volume: "",
    design_value: "",
    name_status: "",
    part_code: "",
    product_name: "",
    category_id: "",
    category: "",
    client_id: "",
    client_name: "",
    fantasy_name: "",
    client_phone: "",
    modeler_id: "",
    modeler_name: "",
    short_description: "",
    info_01: "",
    info_02: "",
    info_03: "",
    info_04: "",
    info_05: "",
    info_06: "",
    info_07: "",
    metal_amount: "",
    billing_type: ""
  });

  useEffect(() => { console.log(productPre) }, [productPre])

  const [showField, setShowField] = useState(false);

  useEffect(() => {
    if (parseInt(productPre["resin_type"], 10) === 1) {
      setShowField(true);
    } else {
      setShowField(false);
    }
  }, [productPre]);

  const getModelistaModal = async (e) => {
    const response = await userServices.getModelista(e);
    setModelista(response.data);
  };

  const handleNewProduct = async (e) => {
    setOpenNewProduct(true);
    const data = {
      token: token,
    };
    getModelistaModal(data);

    const userData = await userServices.userSearch(token);
    setUsers(userData.data);

    const productPre = await productServices.checkProductPre(token);
    setProductPre(productPre.data.product);
  };

  const handleInputChange = (e, f) => {
    const value = e.target.value;
    const field = f;
    setProductPre((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleProductChange = (e, field) => {
    const value = e.target.value;
    setModalProduct((prevState) => ({
      ...prevState,
      product: {
        ...prevState.product,
        [field]: value,
      },
    }));
  };

  const handleCadastroNewProduct = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    e.preventDefault();
    const response = await productServices.update(token, productPre);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleCloseNewProduct = () => {
    setOpenNewProduct(false);
  };

  function combineData(productTypeNailing, typeNailing) {
    const combinedData = {};

    productTypeNailing.forEach((product) => {
      const typeId = product.nt_id;
      const typeName = typeNailing.find((type) => type.id === typeId)?.name;

      if (typeName) {
        if (!combinedData[typeName]) {
          combinedData[typeName] = {};
        }

        combinedData[typeName][product.nt_id] = product.amount;
      }
    });
    return combinedData;
  }

  const combinedData = combineData(productTypeNailing, typeNailing);

  const editProduct = (id, name) => {

    setModalProductContent({ id: id, name: name });
    setModalProductEditShow(true);
  };

  const getResinType = async () => {
    const response = await productServices.getResinType(token);
    if (response.data.status === "success") {
      setResin(response.data.resin_type);
    }
  };

  const [orderData, setOrderData] = useState({

    id_product: "",
    order_type: 1,
    id_status: 1,
    product_metal: "",
    product_metal_color: "",
    prioridade: 'media',
    amount_product: 0,
    amount_product_metal: productWeight,
    amount_product_metal_total: 0,
    ordder_description: newProductDescription,
    id_client: "",
    pair: "N",

  })

  const handleClose = () => {
    setModalProductEditShow(false);
    setModalProductShow(false);
    setModalImageModule(false);
    setModalPedido(false);
    setMetalInsert(false);

    setOrderData({

      id_product: "",
      order_type: 1,
      id_status: 1,
      product_metal: "",
      product_metal_color: "",
      prioridade: 'media',
      amount_product: 0,
      amount_product_metal: productWeight,
      amount_product_metal_total: 0,
      ordder_description: newProductDescription,
      id_client: "",
      pair: "N",

    })
  };

  const handleProductPdf = async (id) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const data = {
      id_product: id,
      token: token,
    };
    const response = await productServices.getProductPdf(data);
    if (response.data.link !== "") {
      var link = response.data.link;
      var anchor = document.createElement("a");
      anchor.style.display = "none";
      document.body.appendChild(anchor);

      // Configure o atributo href e download
      anchor.href = link;
      anchor.setAttribute("target", "_blank");
      toast.dismiss();
      toast.success("Documento gerado com sucesso!");
      // Dispare um clique simulado no elemento de âncora
      setTimeout(() => {
        anchor.click();
      }, 1000);

      // Remova o elemento de âncora
      document.body.removeChild(anchor);
    }
    ;
  };

  const handleOpenModalImage = (id) => {
    setModalImageModule(true);
    handleAttachment(id, token);
  };



  useEffect(() => { setOrderData({ ...orderData, ordder_description: newProductDescription }) }, [newProductDescription])

  // useEffect(() => {
  //   setOrderData({ ...orderData, id_product: modalProduct?.product?.product_id });
  // }, [modalProduct])

  const handleSetCliente = (id_client) => {

    setOrderData({ ...orderData, id_client: id_client });
  }

  useEffect(() => { console.log(orderData) }, [orderData])

  useEffect(() => {
    if (modalProduct?.product) {

      setOrderData((prevData) => ({
        ...prevData,
        id_client:
          modalProduct.product.client_id !== '44'
            ? modalProduct.product.client_id
            : "",
        id_product: modalProduct.product.product_id || "",
      }));
    }
  }, [modalProduct]);

  const ajaxNewOrder = async (e) => {
    e.preventDefault();
    toast.loading("Procesando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formDataObject = {
      action: "create_order",
      token: token,
      ...orderData
    };


    const response = await apiServices.createOrder(formDataObject);

    if (response.data.status === "info") {
      toast.dismiss();
      toast.info(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (response.data.status === "error") {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (response.data.status === "success") {
      setModalPedido(false);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleClose();
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let formData = event.target.pesquisar.value;
    if (formData.length > 1) {
      setSearchKey(formData);
    } else if (formData.length === 0) {
      setSearchKey("");
    }
  };

  const handleSearchNoneValue = (event) => {
    let formData = event.target.value;
    console.log(formData);
    if (formData.length === 0) {
      setSearchKey(""); // Limpa o estado
    } else {
      setSearchKey(formData); // Atualiza com o valor digitado
    }
  }

  const handleProductType = (event) => {
    const value = event.target.value;
    setProductType(value);
  };

  const handlePageClick = async (pageNumber) => {
    setProductLoading(true);
    setCurrentPage(pageNumber);
    await productServices
      .getProducts(token, pageNumber, searchKey, productType)
      .then((response) => {
        setProducts(response.data.products);

        setLink(response.data.link);
        setTotalPages(response.data.total_pages);
        setProductLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateProduct = async (id_product, id_status, token) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    setModalProductEditShow(false);
    await productServices
      .updateProduct(id_product, id_status, token)
      .then((response) => {
        if (id_status !== 4) {
          if ((response.data.status = "success")) {
            toast.dismiss();
            toast.success(response.data.msg, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            handleProductDetail(id_product);
            renderProducts(token, 1, searchKey, productType);
          }
        } else {
          if ((response.data.status = "success")) {
            toast.dismiss();
            toast.success(response.data.msg, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            renderProducts(token, 1, searchKey, productType);
            setModalProductShow(false);
          }
        }
      });
  };

  const renderProducts = async (token, page, searchKey, productType) => {
    await productServices
      .getProducts(token, page, searchKey, productType)
      .then((response) => {
        setProducts(response.data.products);
        setLink(response.data.link);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => console.log(error));
  };

  const handleMaterial = async (id, token) => {
    const responseMaterial = await productServices.selectListMaterial(
      id,
      token
    );
    setMaterial(responseMaterial.data);
  };

  const getClients = async (token) => {
    const responseClients = await userServices.userSearch(token);
    setUsers(responseClients.data);
  };

  const handleNewOrder = async (id) => {
    setModalPedido(true);

    handleMaterial(id, token);

    await getClients(token);

    const responseMaterialColor = await productServices.selectListMaterialcolor(
      id,
      token
    );
    setMaterialColor(responseMaterialColor.data);

    const responseProductDescription =
      await productServices.getProductDescription(id, token);
    setProductWeight(
      responseProductDescription.data.product.metal_amount
    );

    getModalProduct(id, token);
    handleAttachment(id, token);
  };

  const handleQuantidadeNecessaria = (e) => {
    let valorRecebido = e.target.value;
    let peso = parseFloat(productWeight);
    let total = valorRecebido * peso;

    setQuantidadeNecessariaState(total);
  };

  const getModalProduct = async (id, token) => {
    const response = await productServices.getProductDescription(id, token);
    setModalProduct({
      permission: response.data.permission,
      product: response.data.product,
    });
    setModalProductDescription(response.data.product.info_07);
    setProductStatus(response.data.product.id_status);
  };

  const getMaterialProduct = async (id, token) => {
    const response = await productServices.getMetalProduct(id, token);
    const metals = response.data.result.map((item) => item);
    setMetalModalProduct(metals);
    const permission = response.data.permission === 0 ? "disabled" : "";
    setPermissionRemoveEdit(permission);
  };

  const getModule = async (id, token) => {
    const response = await productServices.getMetalModule(id, token);
    const modules = response.data.result.map((item) => item);
    setMetalModule(modules);
  };

  const getNailing = async (id, token) => {
    const response = await productServices.getMetalNailing(id, token);
    const nailing = response.data.result.map((item) => item);
    setMetalNailing(nailing);
  };

  const getTypeNailing = async (id, token) => {
    const data = {
      product: id,
      token: token,
    };
    const response = await productServices.getTypeNailing(data);
    setTypeNailing(response.data);
  };

  const getProductTypeNailing = async (id, token) => {
    const data = {
      product: id,
      token: token,
    };
    const response = await productServices.getProductTypeNailing(data);
    setProductTypeNailing(response.data.result);
  };
  const [permissionRemoveEdit, setPermissionRemoveEdit] = useState("");

  const getTypeAttachment = async () => {
    const response = await productServices.getTypeAttachment(token);
    setSelectTypeAttachment(response.data);
  };

  const handleProductDetail = async (id) => {
    handleMaterial("", token);
    setProductId(id);
    getClients(token);
    setModalProductShow(true);
    const data = {
      token: token,
    };
    try {
      await getModelistaModal(data);
      await handleGetTipoAnexo(0, token);
      await getModalProduct(id, token);
      await getMaterialProduct(id, token);
      await getModule(id, token);
      await getNailing(id, token);
      await getTypeNailing(id, token);
      await getProductTypeNailing(id, token);
      await getTypeAttachment();
      await handleAttachment(id, token);
      await handleAttachmentType(id, token);
      await getResinType(token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAttachment = async (id, token) => {
    const response = await productServices.getMetalAttachment(id, token);
    const attachment = response.data.result.map((item) => item);
    setMetalAttachment(attachment);
  };

  const handleAttachmentType = async (id, token) => {
    const response = await productServices.selectListNailingFormat(id, token);
    setTamanhoCravacao(response.data);
  };

  const handleGetTipoAnexo = async (e) => {
    const response = await productServices.selectListAttachmentType(0, token);
    setAnexo(response.data);
  };

  const handleChangeTypeNailing = (typeId, newValue) => {
    const updatedProductTypeNailing = [...productTypeNailing]; // Cria uma cópia do array existente

    const existingProductType = updatedProductTypeNailing.find(
      (item) => item.nt_id === typeId
    );

    if (existingProductType) {
      existingProductType.amount = newValue; // Atualiza o valor existente
    } else {
      // Se o tipo não existe no array, cria um novo objeto
      updatedProductTypeNailing.push({ nt_id: typeId, amount: newValue });
    }

    setProductTypeNailing(updatedProductTypeNailing);
  };

  const renderedInputs = typeNailing.map((type) => {
    const productType = productTypeNailing.find(
      (ptype) => ptype.nt_id === type.id
    );

    return (
      <div key={type.id} style={{ width: "180px" }} className="mb-3">
        <Form.Label>{type.name}</Form.Label>

        <Form.Control
          id={type.id}
          name={type.id}
          value={productType ? productType.amount : ""}
          disabled={!metalInsert}
          onChange={(e) => handleChangeTypeNailing(type.id, e.target.value)}
        ></Form.Control>
      </div>
    );
  });

  const saveProduct = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    e.preventDefault();
    const response = await productServices.update(token, modalProduct.product);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleMetalInsert = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      token: token,
      product: modalProduct.product.product_id,
      action: "product_metal_Add",
      type: formData.get("type"),
      peso: formData.get("peso"),
    };

    const response = await productServices.productMetalAdd(data);
    if (response.data.status === "success") {
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setMetalInsert(false);
      handleProductDetail(productId);
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    ;
  };

  const removeMetal = async (id) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = {
      id: id,
      token: token,
    };

    const response = await productServices.removeMetal(data);
    ;
    if (response.data.status === "success") {
      handleProductDetail(productId);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const addCravacao = async (e) => {
    e.preventDefault();
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formData = new FormData(e.target);
    const data = {
      action: "product_nailing_Add",
      id_product: productId,
      id_module: formData.get("modulo"),
      stone: formData.get("materia"),
      description: formData.get("descricao"),
      id_format: formData.get("formato"),
      amount: formData.get("quantidade"),
      token: token,
    };



    const response = await productServices.addCravacao(data);
    if (response.data.status === "success") {
      handleProductDetail(productId);
      setMetalInsert(false);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg);
    }
  };

  const addTypeNailing = async (e) => {
    e.preventDefault();
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = [...productTypeNailing];

    const nailingData = {
      action: "type_nailing_Add",
      id_product: productId,
      nailing_central: "",
      nailing_grifas: "",
      nailing_grifas_maior: "",
      nailing_inglesa: "",
      nailing_pave: "",
      nailing_diferencial: "",
    };

    for (let i = 0; i < data.length; i++) {
      const type = data[i];
      if (type.nt_id === 1) {
        nailingData.nailing_pave = type.amount;
      } else if (type.nt_id === 2) {
        nailingData.nailing_grifas = type.amount;
      } else if (type.nt_id === 3) {
        nailingData.nailing_central = type.amount;
      } else if (type.nt_id === 4) {
        nailingData.nailing_inglesa = type.amount;
      } else if (type.nt_id === 5) {
        nailingData.nailing_diferencial = type.amount;
      } else if (type.nt_id === 6) {
        nailingData.nailing_grifas_maior = type.amount;
      }
    }



    const response = await productServices.addTypeNailing(
      productId,
      token,
      nailingData
    );
    if (response.data.status === "success") {
      handleProductDetail(productId);
      setMetalInsert(false);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const [nailingEditingCravacao, setNailingEditingCravacao] = useState({})
  const [nailingAmount, setNailingAmount] = useState(0);

  const handleStartEditingCravacao = (cravacao) => {
    setNailingEditingCravacao(cravacao)
    setNailingAmount(cravacao.amount)
  }

  const handleSubmitEditingCravacao = async () => {
    const payload = {
      nailing_id: nailingEditingCravacao.id,
      amount: nailingAmount,
      token: token
    }

    const response = await productServices.updateProductNailing(payload);
    if (response.data.status == 'success') {
      toast.success("Produto editado com sucesso!")
      setNailingAmount(0)
      setNailingEditingCravacao({})
      handleProductDetail(productId)
    } else {
      toast.error('Ocorreu um erro ao editar produto. Tente novamente ou entre em contato com o nosso suporte.')
    }
  }


  const handleRemoveCravacao = async (e) => {
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const data = {
      id: e,
      token: token,
    };

    const response = await productServices.removeCravacao(data);
    if (response.data.status === "success") {
      handleProductDetail(productId);
      setMetalInsert(false);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg);
    }
  };

  const addModule = async (e) => {
    e.preventDefault();
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formData = new FormData(e.target);
    const data = {
      action: "product_module_Add",
      token: token,
      id_product: productId,
      descricao: formData.get("descricao"),
      peso: formData.get("peso"),
      fileName: file.name,
      anexo: file,
    };

    const response = await productServices.productModuleAdd(data);
    if (response.data.status === "success") {
      handleProductDetail(productId);
      setMetalInsert(false);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg);
    }
  };

  const removeModule = async (id) => {
    const data = {
      id: id,
      token: token,
    };
    const response = await productServices.removeModule(data);
    if (response.data.status === "success") {
      toast.dismiss();
      handleProductDetail(productId);
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const addAnexo = async (e) => {
    e.preventDefault();
    toast.loading("Processando...", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const formData = new FormData(e.target);
    const data = {
      action: "product_module_Add",
      token: token,
      id_product: productId,
      descricao: formData.get("descricao"),
      tipo: formData.get("tipo"),
      fileName: productAnexo.name,
      anexo: productAnexo,
    };

    const response = await productServices.productAttachmentAdd(data);
    if (response.data.erro === "0") {
      handleProductDetail(productId);
      setMetalInsert(false);
      toast.dismiss();
      toast.success(response.data.msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.msg);
    }
  };

  useEffect(() => {
    renderProducts(token, 1, searchKey, productType);
  }, [token, searchKey, productType]);

  const [duplicacaoProdutoData, setDuplicacaoProdutoData] = useState({
    produto: null,
    quantidade: 0
  })
  const [openDuplicaProduto, setOpenDuplicaProduto] = useState(false)
  const [produtoSelecionado, setProdutoSelecionado] = useState({})
  const handleOpenDuplicaProduto = (produto) => {
    setProdutoSelecionado(produto);
    setOpenDuplicaProduto(true)
    setDuplicacaoProdutoData(prevData => ({
      produto: produto.ID
    }))
  }

  const handleCloseDuplicaProduto = () => {
    setOpenDuplicaProduto(false)
    setProdutoSelecionado({})
    setDuplicacaoProdutoData({
      produto: null,
      quantidade: 0
    })
  }

  const handleDuplicaProduto = async () => {

    const response = await productServices.duplicarProdutos({
      ...duplicacaoProdutoData,
      token: token
    })

    const nomeProduto = produtoSelecionado.NAME;

    if (response.data.status == "success") {
      toast.success(response.data.message)
      handleCloseDuplicaProduto();
      setSearchKey(nomeProduto);
    } else {
      toast.error(response.data.message)
    }
  }
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Produtos</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <ButtonGroup aria-label="Basic checkbox toggle d-flex button group">
                    <input
                      type="radio"
                      className="btn-check"
                      name="option"
                      id="btncheck1"
                      onClick={handleProductType}
                      value={0}
                      autoComplete="off"
                    />
                    <label className="btn btn-primary" htmlFor="btncheck1">
                      Todos os Produtos
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="option"
                      id="btncheck2"
                      onClick={handleProductType}
                      value={1}
                      autoComplete="off"
                    />
                    <label className="btn btn-primary" htmlFor="btncheck2">
                      CriatGold
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="option"
                      id="btncheck3"
                      onClick={handleProductType}
                      value={2}
                      autoComplete="off"
                    />
                    <label className="btn btn-primary" htmlFor="btncheck3">
                      Meus Produtos
                    </label>
                  </ButtonGroup>
                </Nav>
              </Card.Header>
              <Card.Body className="w-100 p-3" style={{ overflowX: "auto" }}>
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="border form-search w-20 mb-3">
                    <Form className="d-flex w-100" onSubmit={handleSearch}>
                      <Form.Control
                        className="w-100"
                        value={searchKey}
                        onChange={(e) => {
                          handleSearchNoneValue(e)
                          console.log(e.target.value)
                        }}
                        placeholder="Pesquisar"
                        id="pesquisar"
                        name="pesquisar"
                      />
                      <Button type="submit" variant="outline">
                        <Search />
                      </Button>
                    </Form>
                  </div>
                  <div>
                    <Button
                      className="d-flex justify-content-between align-items-center text-center"
                      onClick={handleNewProduct}
                    >
                      <Plus />
                      Cadastro
                    </Button>
                  </div>
                </div>
                <Table>
                  <tbody>
                    {products.map((product) => (
                      <tr className="bg-glass">
                        <td className="custom=hover">
                          <div className="product-thumb">
                            <img src={product.FILE} alt="" />
                          </div>
                        </td>
                        <td
                          className="custom-hover"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleProductDetail(product.ID)}
                          value={product.ID}
                        >
                          <h6 className=" mb-1">
                            <Link>{product.NAME}</Link>
                          </h6>
                          <span className="fs-sm text-secondary">
                            {product.DESCRIPTION}
                          </span>
                        </td>
                        <td>
                          <label className="d-block text-secondary mb-1 text-center">
                            <i className="ri-scales-2-line"></i>
                          </label>
                          <span className="d-block fw-medium text-dark ff-numerals text-center">
                            {product.WEIGHT}
                          </span>
                        </td>
                        <td>
                          <label className="d-block text-secondary text-center fs-sm mb-1">
                            Status
                          </label>
                          <span className="d-block fw-medium text-center text-dark ff-numerals">
                            <Badge>{product.STATUS}</Badge>
                          </span>
                        </td>

                        <td>
                          <label className="d-block text-secondary text-center fs-sm mb-1">
                            Categoria
                          </label>
                          <span className="d-block fw-medium text-dark text-center ff-numerals">
                            {product.CATEGORY}
                          </span>
                        </td>
                        <td className="text-center">
                          <label className="d-block text-secondary fs-sm mb-1">
                            Ações
                          </label>
                          <div className="text-center justify-content-center align-items-center d-flex">
                            <Button
                              id="edita_produto"
                              className="mx-1 btn-icon"
                              onClick={() =>
                                editProduct(product.ID, product.NAME)
                              }
                              value={product.ID}
                            >
                              <i className="ri-pencil-fill fs-18 lh-1"></i>
                            </Button>
                            <Tooltip anchorSelect="#edita_produto" place="top">Edita produto</Tooltip>
                            <Button
                              id="pdf_produto"
                              className="mx-1 btn-icon"
                              onClick={() => handleProductPdf(product.ID)}
                            >
                              <i className="ri-download-2-fill"></i>
                            </Button>
                            <Tooltip anchorSelect="#pdf_produto" place="top">PDF do produto</Tooltip>
                            <Button
                              id="imagem_produto"
                              className="mx-1 btn-icon"
                              onClick={() => handleOpenModalImage(product.ID)}
                              value={product.ID}
                            >
                              <i className="ri-image-fill"></i>
                            </Button>

                            <Tooltip anchorSelect="#imagem_produto" place="top">Imagem do produto</Tooltip>
                            <Button
                              id="duplica_produto"
                              className="mx-1 btn-icon"
                              onClick={() => handleOpenDuplicaProduto(product)}
                              value={product.ID}
                            >
                              <i class="ri-file-copy-2-line"></i>
                            </Button>
                            <Tooltip anchorSelect="#duplica_produto" place="top">Duplicar produto</Tooltip>
                            <Button
                              id="novo_pedido"
                              className="mx-1 btn-icon"
                              onClick={() => handleNewOrder(product.ID)}
                              value={product.ID}
                            >
                              <i className="ri-shopping-bag-fill"></i>
                            </Button>
                            <Tooltip anchorSelect="#novo_pedido" place="top">Novo pedido</Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Modal
                  size="lg"
                  centered
                  fullscreen="md"
                  show={modalProductEditShow}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Alterar status da peça para edição
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <h4 className="text-center">
                        Deseja iniciar o processo de edição da peça?
                      </h4>
                      <h4>{modalProductContent.name}</h4>
                      <Button
                        onClick={() =>
                          updateProduct(modalProductContent.id, 5, token)
                        }
                      >
                        Confirmar
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal size="xl" show={modalProductShow} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {modalProduct.product.product_name}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card>
                      <Card.Header>Descrição da Peça</Card.Header>
                      <Card.Body>
                        <form onSubmit={saveProduct}>
                          <Row className="border">
                            <Col>
                              <dl>
                                <dt>Código do Cliente:</dt>
                                <dd>{modalProduct.product.client_id}</dd>
                              </dl>
                            </Col>
                            <Col md={6}>
                              <dl>
                                <dt>Cliente:</dt>
                                <dd>
                                  <Form.Select
                                    name="modelista"
                                    id="modelista"
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "client_id")
                                    }
                                    value={modalProduct.product.client_id}
                                    maxLength="64"
                                    required
                                  >
                                    <option value="">---Selecione---</option>
                                    {users.map((user) => (
                                      <option value={user.id} key={user.id}>
                                        {user.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Telefone:</dt>
                                <dd>{modalProduct.product.client_phone}</dd>
                              </dl>
                            </Col>
                          </Row>

                          <Row className="border">
                            <Col>
                              <dl>
                                <dt>Modelista Responsável:</dt>
                                <dd>
                                  <Form.Select
                                    name="modelista"
                                    id="modelista"
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "modeler_id")
                                    }
                                    value={modalProduct.product.modeler_id}
                                    maxLength="64"
                                    required
                                  >
                                    <option value="">---Selecione---</option>
                                    {modelista.map((user) => (
                                      <option value={user.id} key={user.id}>
                                        {user.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Código da Peça:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "part_code")
                                    }
                                    value={modalProduct.product.part_code}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Nome da peça:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "product_name")
                                    }
                                    value={modalProduct.product.product_name}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Categoria:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "category")
                                    }
                                    value={modalProduct.product.category}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Peso Estimado:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "info_01")
                                    }
                                    value={modalProduct.product.info_01}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                          </Row>

                          <Row className="border">
                            <Col>
                              <dl>
                                <dt>Descrição Resumida:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(
                                        e,
                                        "short_description"
                                      )
                                    }
                                    value={
                                      modalProduct.product.short_description
                                    }
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Espessura Média da Chapa:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "info_02")
                                    }
                                    value={modalProduct.product.info_02}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Espessura Média das Grifas:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "info_03")
                                    }
                                    value={modalProduct.product.info_03}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                          </Row>

                          <Row className="border">
                            <Col>
                              <dl>
                                <dt>Tamanho, Largura e Espessura do Aro:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "info_04")
                                    }
                                    value={modalProduct.product.info_04}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Tamanho do Colar (Colares e Rivieras):</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "info_05")
                                    }
                                    value={modalProduct.product.info_05}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                            <Col>
                              <dl>
                                <dt>Argolas:</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert ? "disabled" : ""}
                                    onChange={(e) =>
                                      handleProductChange(e, "info_06")
                                    }
                                    value={modalProduct.product.info_06}
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                          </Row>

                          <Row className="border d-flex flex-row">
                            <Col>
                              <dl>
                                <dt>Volume da peça (ml)</dt>
                                <dd>
                                  <Form.Control
                                    disabled={metalInsert !== true}
                                    value={modalProduct.product.product_volume}
                                    onChange={(e) =>
                                      handleProductChange(e, "product_volume")
                                    }
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>

                            <Col>
                              <dl>
                                <dt>Tipo de resina</dt>
                                <dd>
                                  <Form.Select
                                    disabled={!metalInsert}
                                    onChange={(e) =>
                                      handleProductChange(e, "resin_type")
                                    }
                                  >
                                    <option>---Selecione---</option>

                                    {resin.map((r, i) => (
                                      <option
                                        key={i}
                                        value={r.id}
                                        selected={
                                          r.id ===
                                          modalProduct.product.resin_type
                                        }
                                      >
                                        {r.description}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </dd>
                              </dl>
                            </Col>

                            <Col>
                              <dl>
                                <dt>Design 3D</dt>
                                <dd>
                                  <Form.Control
                                    disabled={!metalInsert}
                                    value={modalProduct.product.design_value}
                                    onChange={(e) =>
                                      handleProductChange(e, "design_value")
                                    }
                                  ></Form.Control>
                                </dd>
                              </dl>
                            </Col>
                          </Row>
                          {modalProduct.product.resin_type == '1' && (
                            <FormGroup>
                              <Form.Label>Cobrança</Form.Label>
                              <Form.Select
                                disabled={!metalInsert}
                                id="billing_type"
                                name="billing_type"
                                value={modalProduct.product.billing_type}
                                onChange={(e) =>
                                  handleProductChange(e, "billing_type")
                                }
                              >
                                <option>--Selecione--</option>
                                <option value={1}>Pequeno</option>
                                <option value={2}>Médio</option>
                                <option value={3}>Grande</option>
                                <option value={4}>Especial</option>
                              </Form.Select>
                            </FormGroup>
                          )}

                          <Row>
                            <Col>
                              <dt>
                                Informações de Pedras (quantidade, espécie,
                                formato, dimensões em altura, largura e
                                profundidade)
                              </dt>
                              <Form.Control
                                disabled={!metalInsert}
                                name="info_pedras"
                                onChange={(e) =>
                                  handleProductChange(e, "info_07")
                                }
                                value={modalProduct.product.info_07}
                                id="info_pedras"
                                placeholder="Informações de Pedras (quantidade, espécie,
                                  formato, dimensões em altura, largura e
                                  profundidade)"
                                as={"textarea"}
                              ></Form.Control>
                            </Col>
                          </Row>

                          <div className="w-100 mt-3 d-flex text-center align-items-center justify-content-center">
                            {metalInsert === true ? (
                              <div className="d-flex justify-content-between">
                                <div>
                                  <Button
                                    type="submit"
                                    className="w-100"
                                    id="submit"
                                    name="submit"
                                  >
                                    Salvar informações
                                  </Button>
                                </div>
                              </div>
                            ) : productStatus === 1 ||
                              productStatus === 2 ||
                              productStatus === 5 ||
                              modalProduct.permission === 1 ? (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMetalInsert(true);
                                }}
                                className="w-20"
                                id="inserir"
                                name="inserir"
                              >
                                Alterar Informações
                              </Button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </Card.Body>
                    </Card>

                    <Accordion alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Metal</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de metal necessário.</h4>
                          </div>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr className="text-center">
                                <th>Tipo</th>
                                <th>Peso (G)</th>
                                <th>Remover</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metalModalProduct.map((metal, index) => (
                                <tr className="text-center" key={index}>
                                  <td>{metal.metal}</td>
                                  <td>{metal.amount.toFixed(2)}</td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      disabled={
                                        productStatus === 1 ||
                                          productStatus === 2 ||
                                          productStatus === 5 ||
                                          permissionRemoveEdit === true
                                          ? ""
                                          : "disabled"
                                      }
                                      onClick={() => removeMetal(metal.id)}
                                    >
                                      <i className="ri-delete-bin-6-line"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <form onSubmit={handleMetalInsert}>
                            {metalInsert === true ? (
                              <div>
                                <Card className="card-one">
                                  <Card.Header>
                                    Preencha as informações abaixo
                                  </Card.Header>
                                  <Card.Body>
                                    <Row>
                                      <Col>
                                        <Form.Select id="type" name="type">
                                          <option>---Selecione---</option>
                                          {material.map((type) => (
                                            <option
                                              value={type.id}
                                              key={type.id}
                                            >
                                              {type.name}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          name="peso"
                                          id="peso"
                                          placeholder="Digite o peso"
                                        ></Form.Control>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="w-100 d-flex text-center align-items-center justify-content-center">
                              {metalInsert === true ? (
                                <Button
                                  type="submit"
                                  className="w-20"
                                  id="submit"
                                  name="submit"
                                >
                                  Atribuir
                                </Button>
                              ) : productStatus === 1 ||
                                productStatus === 2 ||
                                productStatus === 5 ? (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setMetalInsert(true);
                                  }}
                                  className="w-20"
                                  id="inserir"
                                  name="inserir"
                                >
                                  Inserir
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Módulos</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de composição</h4>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between">
                            {metalModule.map((modules) => (
                              <Card
                                style={{ width: "500px" }}
                                className="mx-2 p-3 d-flex flex-row mb-2"
                              >
                                <Avatar size={"xl"} img={modules.file} />
                                <div className="ms-3 w-100">
                                  <div className="w-100 d-flex flex-wrap justify-content-between">
                                    <h4 className="card-value mb-1">
                                      {modules.name}
                                    </h4>
                                    <div className="d-flex">
                                      <Button
                                        variant="danger"
                                        disabled={
                                          productStatus === 1 ||
                                            productStatus === 2 ||
                                            productStatus === 5 ||
                                            permissionRemoveEdit === true
                                            ? ""
                                            : "disabled"
                                        }
                                        onClick={() => removeModule(modules.id)}
                                        className="mx-1"
                                      >
                                        <i className="ri-delete-bin-6-line"></i>
                                      </Button>
                                    </div>
                                    <Image
                                      src={modules.file}
                                      width={"100%"}
                                    ></Image>
                                    <div className="d-flex flex-row w-100 justify-content-end align-items-center">
                                      <UpdateModuleImage module={modules} />
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </div>

                          <form onSubmit={addModule}>
                            {metalInsert === true ? (
                              <div>
                                <Card className="card-one">
                                  <Card.Header>
                                    Preencha as informações abaixo
                                  </Card.Header>
                                  <Card.Body>
                                    <Row>
                                      <Col>
                                        <Form.Label for="descricao">
                                          Descrição
                                        </Form.Label>
                                        <Form.Control
                                          id="descricao"
                                          name="descricao"
                                        ></Form.Control>
                                      </Col>
                                      <Col>
                                        <Form.Label for="peso">Peso</Form.Label>
                                        <Form.Control
                                          name="peso"
                                          id="peso"
                                        ></Form.Control>
                                      </Col>
                                    </Row>
                                    <div className="mt-3">
                                      <Form.Label for="anexo">Anexo</Form.Label>
                                      <Form.Control
                                        id="anexo"
                                        name="anexo"
                                        onChange={(e) =>
                                          setFile(e.target.files[0])
                                        }
                                        type="file"
                                      ></Form.Control>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="w-100 d-flex text-center align-items-center justify-content-center">
                              {metalInsert === true ? (
                                <Button
                                  type="submit"
                                  className="w-20"
                                  id="submit"
                                  name="submit"
                                >
                                  Atribuir
                                </Button>
                              ) : productStatus === 1 ||
                                productStatus === 2 ||
                                productStatus === 5 ? (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setMetalInsert(true);
                                  }}
                                  className="w-20"
                                  id="inserir"
                                  name="inserir"
                                >
                                  Inserir
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Cravação</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de pedras.</h4>
                          </div>
                          <Table bordered>
                            <thead className="table-dark">
                              <tr>
                                <th>Módulo</th>
                                <th>Mat.Prima</th>
                                <th>Descrição</th>
                                <th>Quantidade</th>
                                <th>Opções</th>
                              </tr>
                            </thead>
                            <tbody>
                              {metalNailing.map((nailing) => (
                                <tr>
                                  <td>{nailing.module}</td>
                                  <td>{nailing.description}</td>
                                  <td>{nailing.stone}</td>
                                  <td>{nailingEditingCravacao.id == nailing.id ?
                                    (
                                      <Form.Control
                                        value={nailingAmount}
                                        onChange={
                                          (e) => setNailingAmount(e.target.value)
                                        } />) : nailing.amount}
                                  </td>
                                  <td>
                                    <ButtonGroup>
                                      {nailingEditingCravacao.id == nailing.id ? <Button style={{ width: "130px" }} className="justify-content-center d-flex flex-row gap-1 align-items-center"
                                        disabled={
                                          productStatus === 1 ||
                                            productStatus === 2 ||
                                            productStatus === 5 ||
                                            permissionRemoveEdit === true
                                            ? ""
                                            : "disabled"
                                        }
                                        onClick={() =>
                                          handleSubmitEditingCravacao()
                                        }
                                      ><Save />
                                        Salvar
                                      </Button> : <Button style={{ width: "130px" }} className="justify-content-center d-flex flex-row gap-1 align-items-center"
                                        disabled={
                                          productStatus === 1 ||
                                            productStatus === 2 ||
                                            productStatus === 5 ||
                                            permissionRemoveEdit === true
                                            ? ""
                                            : "disabled"
                                        }
                                        onClick={() =>
                                          handleStartEditingCravacao(nailing)
                                        }
                                      ><Edit />
                                        Editar
                                      </Button>}

                                      <Button style={{ width: "130px" }} className="justify-content-center d-flex flex-row gap-1 align-items-center" variant="danger"
                                        disabled={
                                          productStatus === 1 ||
                                            productStatus === 2 ||
                                            productStatus === 5 ||
                                            permissionRemoveEdit === true
                                            ? ""
                                            : "disabled"
                                        }
                                        onClick={() =>
                                          handleRemoveCravacao(nailing.id)
                                        }
                                      >
                                        <Trash2 />
                                        Remover
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <form onSubmit={addCravacao}>
                            {metalInsert === true ? (
                              <div>
                                <Card className="card-one">
                                  <Card.Header>
                                    Preencha as informações abaixo
                                  </Card.Header>
                                  <Card.Body>
                                    <Row>
                                      <Col>
                                        <Form.Label for="descricao">
                                          Módulo
                                        </Form.Label>
                                        <Form.Select id="modulo" name="modulo">
                                          <option>---Selecione---</option>
                                          {metalModule.map((modules) => (
                                            <option
                                              key={modules.id}
                                              value={modules.id}
                                            >
                                              {modules.name}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                      <Col>
                                        <Form.Label for="peso">
                                          Materia Prima
                                        </Form.Label>
                                        <Form.Control
                                          name="materia"
                                          id="materia"
                                        ></Form.Control>
                                      </Col>
                                      <Col>
                                        <Form.Label for="descricao">
                                          Descrição
                                        </Form.Label>
                                        <Form.Control
                                          name="descricao"
                                          id="descricao"
                                        ></Form.Control>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col>
                                        <Form.Label for="formato">
                                          Formato
                                        </Form.Label>
                                        <Form.Select
                                          id="formato"
                                          name="formato"
                                        >
                                          <option>---Selecione---</option>
                                          {tramanhoCravacao.map((type) => (
                                            <option
                                              key={type.id}
                                              value={type.id}
                                            >
                                              {type.name}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                      <Col>
                                        <Form.Label for="quantidade">
                                          Quantidade
                                        </Form.Label>
                                        <Form.Control
                                          type="number"
                                          defaultValue={0}
                                          id="quantidade"
                                          name="quantidade"
                                        ></Form.Control>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="w-100 d-flex text-center align-items-center justify-content-center">
                              {metalInsert === true ? (
                                <Button
                                  type="submit"
                                  className="w-20"
                                  id="submit"
                                  name="submit"
                                >
                                  Atribuir
                                </Button>
                              ) : productStatus === 1 ||
                                productStatus === 2 ||
                                productStatus === 5 ? (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setMetalInsert(true);
                                  }}
                                  className="w-20"
                                  id="inserir"
                                  name="inserir"
                                >
                                  Inserir
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Tipo de Cravação</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 text-center mb-3">
                            <h4>Especificação de pedras.</h4>
                          </div>

                          <form onSubmit={addTypeNailing}>
                            <Row className="card-one d-flex align-items-center justify-content-between">
                              {renderedInputs}
                            </Row>

                            <div className="w-100 d-flex text-center align-items-center justify-content-center">
                              {metalInsert === true ? (
                                <Button
                                  type="submit"
                                  className="w-20"
                                  id="submit"
                                  name="submit"
                                >
                                  Atribuir
                                </Button>
                              ) : productStatus === 1 ||
                                productStatus === 2 ||
                                productStatus === 5 ? (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setMetalInsert(true);
                                  }}
                                  className="w-20"
                                  id="inserir"
                                  name="inserir"
                                >
                                  Inserir
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Anexos</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col>
                              {metalAttachment.map((image, index) => (
                                <Card className="mb-3" style={{ width: "40%" }}>
                                  <Card.Header key={index} closeButton>
                                    <Card.Title>{image.description}</Card.Title>
                                  </Card.Header>
                                  <Card.Body>
                                    <Image src={image.file} width={"100%"}></Image>
                                  </Card.Body>
                                </Card>
                              ))}</Col>
                          </Row>

                          <Row>
                            <span>Preencha as Informações abaixo</span>
                            <Col>

                              <form onSubmit={addAnexo}>
                                <div>
                                  <Card className="card-one">
                                    <Card.Body>





                                      <Row>
                                        <Col>
                                          <Form.Label for="quantidade">
                                            Descrição
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            id="descricao"
                                            name="descricao"
                                          ></Form.Control>
                                        </Col>
                                        <Col>
                                          <Form.Label for="tipo">
                                            Tipo
                                          </Form.Label>
                                          <Form.Select id="tipo" name="tipo">
                                            <option value={0}>
                                              ---Selecione---
                                            </option>
                                            {typeAnexo.map((type) => (
                                              <option
                                                key={type.id}
                                                value={type.id}
                                              >
                                                {type.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Col>
                                      </Row>
                                      <div className="mt-3">


                                        <Form.Label for="anexo">
                                          Adicionar anexo
                                        </Form.Label>
                                        <Form.Control
                                          id="anexo"
                                          name="anexo"
                                          onChange={(e) =>
                                            setProducAnexo(e.target.files[0])
                                          }
                                          type="file"
                                        ></Form.Control>



                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                                <div className="w-100 d-flex text-center align-items-center justify-content-center">
                                  {metalInsert === true ? (
                                    <Button
                                      type="submit"
                                      className="w-20"
                                      id="submit"
                                      name="submit"
                                    >
                                      Atribuir
                                    </Button>
                                  ) : productStatus === 1 ||
                                    productStatus === 2 ||
                                    productStatus === 5 ? (
                                    <Button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setMetalInsert(true);
                                      }}
                                      className="w-20"
                                      id="inserir"
                                      name="inserir"
                                    >
                                      Inserir
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </form>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Fechar
                    </Button>
                    <Button
                      variant="primary"
                      disabled={
                        productStatus === 1 ||
                          productStatus === 2 ||
                          productStatus === 5
                          ? false
                          : true
                      }
                      onClick={() =>
                        productStatus === 1 ||
                          productStatus === 2 ||
                          productStatus === 5
                          ? updateProduct(productId, 4, token)
                          : toast.error(
                            "Você não tem permissão para editar o produto.",
                            { position: toast.POSITION.BOTTOM_CENTER }
                          )
                      }
                    >
                      Finalizar Edição
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  size="lg"
                  centered
                  fullscreen="md"
                  show={modalImageModule}
                  onHide={handleClose}
                >
                  {metalAttachment.map((image, index) => (
                    <>
                      <Modal.Header key={index} closeButton>
                        <Modal.Title>{image.description}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image src={image.file} width={"100%"}></Image>
                      </Modal.Body>
                    </>
                  ))}
                </Modal>

                <Modal
                  size="xl"
                  centered
                  fullscreen="md"
                  show={openNewProduct}
                  onHide={handleCloseNewProduct}
                >
                  <Modal.Header>
                    PRÉ-CADASTRO E INFORMAÇÕES DE PRODUTO P/ MODELO 3D
                  </Modal.Header>
                  <form onSubmit={handleCadastroNewProduct}>
                    <ModalBody>
                      <FormGroup className="d-flex mx-1">
                        <div className="w-100">
                          <label for="modelista">MODELISTA</label>
                          <Form.Select
                            type="text"
                            name="modelista"
                            id="modelista"
                            onChange={(e) => handleInputChange(e, "modeler_id")}
                            value={productPre.modeler_id}
                            maxLength="64"
                            required
                          >
                            <option>---Selecione---</option>
                            {modelista.map((user) => (
                              <option value={user.id} key={user.id}>
                                {user.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>

                        <div className="w-100 mx-1">
                          <label for="cliente">CLIENTE</label>
                          <Form.Select
                            name="cliente"
                            id="cliente"
                            onChange={(e) => handleInputChange(e, "client_id")}
                            value={productPre.client_id}
                            maxLength="64"
                            required
                          >
                            <option>---Selecione---</option>
                            {users.map((client) => (
                              <option value={client.id} key={client.id}>
                                {client.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="w-100 mx-1">
                          <label for="codigo_peca">
                            CÓDIGO DA PEÇA (Caso Aplicável)
                          </label>
                          <Form.Control
                            type="text"
                            name="codigo_peca"
                            id="codigo_peca"
                            onChange={(e) => handleInputChange(e, "part_code")}
                            value={productPre.part_code}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>
                      </FormGroup>

                      <FormGroup className="d-flex w-100">
                        <div className="w-100 mx-1">
                          <label for="categoria">CATEGORIA</label>
                          <Form.Select
                            type="select"
                            name="categoria"
                            id="categoria"
                            onChange={(e) =>
                              handleInputChange(e, "category_id")
                            }
                            value={productPre.category_id}
                            required
                          >
                            <option value="0">SELECIONE</option>
                            <option value="1">Anel</option>
                            <option value="3">Brinco</option>
                            <option value="2">Colar</option>
                            <option value="6">Piercing</option>
                            <option value="4">Pingente</option>
                            <option value="5">Pulseira</option>
                            <option value="9">Rivieras</option>
                            <option value="10">Tarraxas</option>
                          </Form.Select>
                        </div>
                        <div className="w-100 mx-1">
                          <label for="nome_peca">Nome da peça</label>
                          <Form.Control
                            type="text"
                            name="nome_peca"
                            id="nome_peca"
                            onChange={(e) =>
                              handleInputChange(e, "product_name")
                            }
                            value={productPre.product_name}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <textarea
                          className="form-control mt-3"
                          onChange={(e) =>
                            handleInputChange(e, "short_description")
                          }
                          value={productPre.short_description}
                          name="descricao"
                          id="descricao"
                          placeholder="Descrição resumida da peça"
                        ></textarea>
                      </FormGroup>

                      <FormGroup className="d-flex mt-3">
                        <div className="w-100 mx-1">
                          <label for="peso_estimado">Peso Estimado</label>
                          <Form.Control
                            type="text"
                            name="peso_estimado"
                            id="peso_estimado"
                            onChange={(e) => handleInputChange(e, "info_01")}
                            value={productPre.info_01}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>
                        <div className="w-100 mx-1">
                          <label for="espessura">
                            Espessura média da chapa
                          </label>
                          <Form.Control
                            type="text"
                            name="espessura"
                            onChange={(e) => handleInputChange(e, "info_02")}
                            value={productPre.info_02}
                            id="espessura"
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>

                        <div className="w-100 mx-1">
                          <label for="espessura_grifas">
                            Espessura média das grifas
                          </label>
                          <Form.Control
                            type="text"
                            name="espessura_grifas"
                            id="espessura_grifas"
                            onChange={(e) => handleInputChange(e, "info_03")}
                            value={productPre.info_03}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>
                      </FormGroup>

                      <FormGroup className="d-flex mt-3">
                        <div className="w-100 mx-1">
                          <label for="tamanho_aro">
                            Tamanho, Largura e Espessura do Aro
                          </label>
                          <Form.Control
                            type="text"
                            name="tamanho_aro"
                            id="tamanho_aro"
                            onChange={(e) => handleInputChange(e, "info_04")}
                            value={productPre.info_04}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>
                        <div className="w-100 mx-1">
                          <label for="tamano_colar">
                            Tamanho do Colar(Colares e Rivieiras)
                          </label>
                          <Form.Control
                            type="text"
                            name="tamano_colar"
                            id="tamano_colar"
                            onChange={(e) => handleInputChange(e, "info_05")}
                            value={productPre.info_05}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>

                        <div className="w-100 mx-1">
                          <label for="info_argolinhas">
                            Informações de Argolinhas (Pingentes)
                          </label>
                          <Form.Control
                            type="text"
                            name="info_argolinhas"
                            id="info_argolinhas"
                            onChange={(e) => handleInputChange(e, "info_06")}
                            value={productPre.info_06}
                            maxLength="64"
                            required
                          ></Form.Control>
                        </div>
                      </FormGroup>

                      <FormGroup className="d-flex flex-row">
                        <div className="w-100 mx-1 my-2">
                          <Form.Label>Tipo de Resina</Form.Label>
                          <Form.Select
                            className="w-100"
                            placeholder="Insira o Tipo de Resina"
                            id="resin_type"
                            name="resin_type"
                            onChange={(e) => handleInputChange(e, "resin_type")}
                          >
                            <option>---Selecione---</option>
                            <option value={1}>T-294 - Borracha</option>
                            <option value={2}>F-445 - Fundição Direta</option>
                          </Form.Select>
                        </div>

                        <div className="w-100 mx-1 my-2">
                          <Form.Label>Volume da peça (ml)</Form.Label>
                          <Form.Control
                            name="product_volume"
                            id="product_volume"
                            onChange={(e) =>
                              handleInputChange(e, "product_volume")
                            }
                            className="w-100"
                            placeholder="Insira o volume da peça"
                          ></Form.Control>
                        </div>

                        <div className="w-100 mx-1 my-2">
                          <Form.Label>Design 3D (R$)</Form.Label>
                          <Form.Control
                            name="design_value"
                            id="design_value"
                            onChange={(e) =>
                              handleInputChange(e, "design_value")
                            }
                            className="w-100"
                            placeholder="Insira o valor do Design 3D"
                          ></Form.Control>
                        </div>
                      </FormGroup>
                      {showField ? (
                        <FormGroup>
                          <Form.Label>Cobrança</Form.Label>
                          <Form.Select
                            id="billing_type"
                            name="billing_type"
                            onChange={(e) =>
                              handleInputChange(e, "billing_type")
                            }
                          >
                            <option>--Selecione--</option>
                            <option value={1}>Pequeno</option>
                            <option value={2}>Médio</option>
                            <option value={3}>Grande</option>
                            <option value={4}>Especial</option>
                          </Form.Select>
                        </FormGroup>
                      ) : null}

                      <div className="w-100 mt-3">
                        <label>
                          Informações de Pedras (quantidade, espécie, formato,
                          dimensões em altura, largura e profundidade)
                        </label>
                        <textarea
                          id="info_pedras"
                          name="info_pedras"
                          onChange={(e) => handleInputChange(e, "info_07")}
                          value={productPre.info_07}
                          className="form-control"
                        ></textarea>
                      </div>
                    </ModalBody>
                    <Modal.Footer>
                      <Button
                        onClick={() => handleCloseNewProduct()}
                        color="secondary"
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => setSubmitType("atualizar")}
                      >
                        Salvar Informações
                      </Button>{" "}
                      <Button
                        type="submit"
                        onClick={() =>
                          setProductPre({ ...productPre, id_status: "2" })
                        }
                      >
                        Finalizar
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>

                <Modal
                  size="xl"
                  centered
                  show={modalPedido}
                  onHide={handleClose}
                >
                  <Modal.Header>
                    <Modal.Title>Novo Pedido</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={ajaxNewOrder}>
                      <Row>
                        <Col className="d-flex text-center flex-wrap justify-content-center align-items-center">
                          <Card className="p-3 h-100 w-100">
                            <h5>
                              Nome da peça: {modalProduct.product.product_name}
                            </h5>
                            <CarouselCustom
                              images={metalAttachment.map((image) => image)}
                            />
                            <h5>{modalProduct.product.short_description}</h5>
                          </Card>
                        </Col>
                        <Col className="text-center">
                          <Card className="p-3 h-100 d-flex justify-content-center">
                            {/* <Row>
                              <label>Tipo de Pedido</label>
                              <Nav className="nav-icon nav-icon-sm ms-auto d-flex text-center align-items-center justify-content-center">
                                <ButtonGroup aria-label="Basic checkbox toggle d-flex button group">
                                  <input
                                    type="radio"
                                    required
                                    className="btn-check"
                                    name="tipo_pedido"
                                    id="tipo_1"
                                    value={3}
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-primary"
                                    htmlFor="tipo_1"
                                  >
                                    Fundição
                                  </label>
                                  <input
                                    type="radio"
                                    required
                                    className="btn-check"
                                    name="tipo_pedido"
                                    id="tipo_2"
                                    value={1}
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-primary"
                                    htmlFor="tipo_2"
                                  >
                                    Produção
                                  </label>
                                </ButtonGroup>
                              </Nav>
                            </Row> */}
                            <Row className="d-flex justify-content-center">
                              <Col className="d-flex flex-wrap mt-3">
                                <label>Tipo de Metal</label>
                                <Form.Select

                                  id="product_metal"
                                  name="product_metal"
                                  onChange={(e) => setOrderData({ ...orderData, product_metal: e.target.value })}
                                  value={orderData.product_metal}
                                >
                                  <option value={""}>Selecione</option>
                                  {material.map((metal) => (
                                    <option value={metal.id} key={metal.id}>
                                      {metal.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              <Col className="d-flex flex-wrap mt-3">
                                <label className="text-center w-100">Cor do Metal</label>
                                <span>{modalProduct.product.category_id == 8}</span>
                                <Form.Select
                                  disabled={!orderData.product_metal}

                                  id="product_metal_color"
                                  name="product_metal_color"
                                  onChange={(e) => setOrderData({ ...orderData, product_metal_color: e.target.value })}
                                >
                                  <option value={""}>Selecione</option>

                                  {modalProduct.product.category_id == 8 ? materialColor.map((color) => {
                                    if (color.id <= 3) {
                                      return (
                                        <option value={color.id} key={color.id}>
                                          {color.name}
                                        </option>
                                      )
                                    }
                                  }) : materialColor.filter(item => orderData.product_metal == 3 ? item.id != "7" : item.id == "7").map((color) => (
                                    <option value={color.id} key={color.id}>
                                      {color.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              <Row>
                                <Col className="mt-3 w-100 text-left">
                                  <span className="w-100 ">Tipo de pedido:</span>
                                  <Nav className="mb-3 nav-icon nav-icon-sm ms-auto d-flex text-center align-items-center justify-content-center">
                                    <Form.Select
                                      className="text-center"
                                      name="tipo_pedido"
                                      id="tipo_pedido"
                                      value={orderData.order_type}
                                      onChange={(e) => setOrderData({ ...orderData, order_type: e.target.value })}
                                    >
                                      <option value={"1"}>Produção</option>
                                      <option value={"4"}>Prototipagem</option>
                                    </Form.Select>
                                  </Nav>
                                  <span className="w-100 ">Un./Par:</span>
                                  <Nav className="mb-3 nav-icon nav-icon-sm ms-auto d-flex text-center align-items-center justify-content-center">
                                    <Form.Select
                                      disabled={modalProduct.product.category_id != 3}
                                      className="text-center"
                                      name="modal_order_form_input_pair"
                                      id="modal_order_form_input_pair"
                                      value={orderData.value}
                                      onChange={(e) => setOrderData({ ...orderData, pair: e.target.value })}
                                    >
                                      <option value={"N"}>Un.</option>
                                      <option value={"S"}>Par(es)</option>
                                    </Form.Select>
                                  </Nav>
                                  <span className="mt-4 w-100">
                                    Peso por peça:
                                  </span>
                                  <span
                                    id="quantidadeNecessaria"
                                    name="quantidadeNecessaria"
                                    className=" form-control"
                                  >
                                    <i className="ri-scales-2-fill"></i>
                                    {modalProduct.product.info_01}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3 w-100">
                                  <label className="w-100">Quantidade:</label>
                                  <Form.Control
                                    className="mt-0 mb-3 text-center w-100"
                                    onChange={(e) => setOrderData({
                                      ...orderData,
                                      amount_product: e.target.value,
                                      amount_product_metal_total: Number(e.target.value) * Number(productWeight),
                                    })}
                                    value={orderData.amount_product}
                                    min={0}
                                    stesp={1}
                                    name="amount_product"
                                    id="amount_product"
                                    type="number"
                                  ></Form.Control>
                                  <span className="w-100">
                                    Peso necessário total:
                                  </span>
                                  <span
                                    id="quantidadeNecessariaTotal"
                                    name="quantidadeNecessariaTotal"
                                    className="form-control"
                                  >
                                    <i className="ri-scales-2-fill"></i>
                                    {Number(orderData?.amount_product_metal_total)?.toFixed(3)}
                                  </span>
                                </Col>
                              </Row>

                              <Row>
                                <Col className="mt-3 w-100">
                                  <label className="w-100">Prioridade:</label>
                                  <Form.Select
                                    className="mt-0 mb-3 text-center w-100"
                                    onChange={(e) => setOrderData({
                                      ...orderData,
                                      prioridade: e.target.value,
                                    })}
                                    value={orderData.prioridade}

                                    name="prioridade"
                                    id="prioridade"
                                  >
                                    {[
                                      { label: "Máxima", value: "maxima" },
                                      { label: "Urgente", value: "urgente" },
                                      { label: "Alta", value: "alta" },
                                      { label: "Média", value: "media" }
                                    ].map(item => (
                                      <option value={item.value} key={item.value}>{item.label}</option>

                                    ))}
                                  </Form.Select>

                                </Col>
                              </Row>
                              <Row className="mt-3 w-100">
                                <label>Informações Complementares:</label>
                                <Editor
                                  onInit={(_evt, editor) => editorRef.current = editor}
                                  onChange={(e) => {
                                    onUpdatingModalProductDescription(e)
                                  }}
                                  apiKey='gsmvci1znnyamtj9fuwif8r32sjrds5s7unvbjbszdm09jxk'
                                  init={{
                                    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons',
                                  }}
                                  initialValue={modalProduct.product.info_07?.replace(/\n/g, "<br>")}
                                />
                                <p>
                                  <div style={{
                                    textAlign: "start"
                                  }} dangerouslySetInnerHTML={{ __html: newProductDescription }}></div>
                                </p>
                              </Row>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      {modalProduct.product.client_id === "44" ? (
                        <Row>
                          <Col md={12}>
                            <Card className="mt-3 w-100 text-center d-flex align-items-center justify-content-center flex-column p-3">
                              {users.length > 1 ? (
                                <>
                                  <Card.Title>Selecione o Cliente</Card.Title>
                                  <Card.Body>
                                    <select
                                      className="form-control"
                                      id="id_client"
                                      name="id_client"
                                      onChange={(e) => setOrderData({ ...orderData, id_client: e.target.value })}
                                      value={orderData.id_client}
                                    >
                                      <option value={""}>Selecione</option>
                                      {users.map((client) => (
                                        <option
                                          value={client.id}
                                          key={client.id}
                                        >
                                          {client.name}
                                        </option>
                                      ))}
                                    </select>
                                  </Card.Body>
                                </>
                              ) : (
                                <>
                                  <Card.Title>Cliente</Card.Title>
                                  {users.map((client) => (
                                    <h4
                                      id="id_client"
                                      name="id_client"
                                      value={client.id}
                                      key={client.id}
                                    >
                                      {client.name}
                                    </h4>
                                  ))}
                                </>
                              )}
                            </Card>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md={12}>
                            <Card className="card-one w-100 text-center d-flex align-items-center justify-content-center flex-column p-3">
                              <Card.Title>Cliente</Card.Title>
                              <Card.Body>
                                <input
                                  type="hidden"
                                  value={modalProduct.product.client_id}
                                  id="id_client"
                                  name="id_client"
                                ></input>
                                <h4>{modalProduct.product.client_name}</h4>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      )}

                      <Card className="mt-3">
                        <Row className="mt-3 mb-3 d-flex justify-content-center">
                          <Button type="submit" className="w-50">
                            Enviar Pedido
                          </Button>
                        </Row>
                      </Card>
                    </Form>
                  </Modal.Body>
                </Modal>

                <Card.Footer className="d-flex justify-content-center">
                  <Pagination className="pagination-space pagination-circled mb-0">
                    {Array.from({ length: totalPages }, (_, index) => {
                      if (
                        index + 1 === currentPage ||
                        index + 1 === 1 ||
                        index + 1 === totalPages ||
                        (index + 1 >= currentPage - 2 &&
                          index + 1 <= currentPage + 2)
                      ) {
                        return (
                          <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageClick(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        );
                      } else if (
                        (index + 1 === currentPage - 3 && currentPage > 5) ||
                        (index + 1 === currentPage + 3 &&
                          currentPage < totalPages - 4)
                      ) {
                        return (
                          <Pagination.Ellipsis
                            key={index + 1}
                            onClick={() => handlePageClick(index + 1)}
                          />
                        );
                      }
                      return null;
                    })}
                  </Pagination>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
        <Footer />
      </div>

      <Modal show={openDuplicaProduto} onHide={handleCloseDuplicaProduto}>
        <Modal.Header closeButton>{produtoSelecionado.NAME}</Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            <h5 className="fw-bold">Atenção! Utilize esta funcionalidade com cautela.</h5>{" "}

            <p>
              A inserção excessiva de produtos pode
              resultar na sobrecarga do sistema, causando períodos de <strong>inatividade</strong> que afetarão
              diretamente a produção da fábrica.

            </p>
            <p>Certifique-se de que a quantidade esteja correta.</p>
          </Alert>
          <Form.Label>Quantidade</Form.Label>
          <Form.Control value={duplicacaoProdutoData.quantidade} onChange={(e) => setDuplicacaoProdutoData(prevData => ({
            ...prevData,
            quantidade: apenasNumerosInteiros(e.target.value)
          }))}
            placeholder="Coloque a quantidade de produtos que deseja"></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDuplicaProduto}>Salvar</Button>
          <Button onClick={handleCloseDuplicaProduto}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment >
  );
}
