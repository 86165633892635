import {
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  Tab,
  TabContainer,
  Table,
  Tabs,
} from "react-bootstrap";
import { userServices } from "../../apiServices/userServices.js";
import { useEffect, useState } from "react";

import Select from "react-select";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";
import Decimal from "decimal.js";
import {
  apenasNumeros,
  apenasNumerosInteiros,
  formatarMoeda,
} from "../../functions/Mask.jsx";
import { CadastroEmprestimos } from "../../components/Emprestimos/Cadastro.jsx";
import { ListaEmprestimo } from "../../components/Emprestimos/Lista.jsx";
import { ModalRecebimentoMetal } from "../../components/ControleMetal/Cliente/recebimento";

const ControleMetal = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});

  const au = localStorage.getItem("au");

  const utp = localStorage.getItem("utp");

  const token = localStorage.getItem("token");
  const getUsers = async () => {
    const request = {
      token,
      page: 1,
      pageSearch: "client",
      searchKey: "",
      limit: 1000000,
    };
    const response = await userServices.getUsers(request);
    setUsers(
      response.data.users?.map((item) => ({
        ...item,
        value: item.ID,
        label: item.NAME,
      }))
    );
  };

  const geraPesoFinalPedido = (polimento_weight) => {
    return polimento_weight.reduce((acc, item) => {
      return acc.plus(new Decimal(item.weight))
    }, new Decimal(0)).toString();
  }

  const [listaTransacoes, setListaTransacoes] = useState([]);
  const [materialIdParaListaDeTransacao, setMaterialIdParaListaDeTransacao] =
    useState(3);

  const [clientIdParaListaDeTransacao, setClientIdParaListaDeTransacao] =
    useState(null);

  const [clienteSelecionado, setClienteSelecionado] = useState({})

  const [numeroPedido, setNumeroPedido] = useState("");

  const handleChangeNumeroPedido = (value) => {
    if (value.length == 0) {
      setNumeroPedido("");
    } else {
      setNumeroPedido(value);
    }
  };

  const [modoLista, setModoLista] = useState(utp == "interno" ? "fabrica" : "cliente");

  const handleListaTransacaoDeCliente = async () => {
    const response = await userServices.listClientLastTransaction({
      client_id: modoLista == "fabrica" ? "44" : clientIdParaListaDeTransacao,
      token,
      material_id: materialIdParaListaDeTransacao,
      modo: modoLista,
      order_code: numeroPedido,
    });

    const lista = response.data.transactions;

    let total = new Decimal(0);

    setListaTransacoes(
      lista?.map((item) => {
        const amount = new Decimal(item.amount_operated);
        total = total.plus(amount);
        return {
          ...item,
          quantidade_acumulada: total.toFixed(3),
        };
      })
    );
  };

  const [somatorioSaldoClientes, setSomatorioClientes] = useState([]);
  const [somatorioSaldoFabrica, setSomatorioSaldoFabrica] = useState([]);
  useEffect(() => {
    handleListaTransacaoDeCliente();
  }, [materialIdParaListaDeTransacao, clientIdParaListaDeTransacao, modoLista]);

  const handleFetch = async () => {
    if (utp != "interno") {
      const response = await userServices.getUserData(token);
      setClientIdParaListaDeTransacao(response.data.result.USERID);
      setUser(response.data.result);
    } else {
      await getUsers();
      const responseSaldoClientes =
        await userServices.getSomatorioSaldoClientes(token);
      setSomatorioClientes(responseSaldoClientes.data.somatorio_saldo_clientes);

      const responseSaldoFabrica = await userServices.getSomatorioSaldoFabrica(
        token
      );
      setSomatorioSaldoFabrica(
        responseSaldoFabrica.data.somatorio_saldo_fabrica
      );
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
      {utp === "interno" && (
        <>
          {/* Somatório de saldo de clientes */}
          <Card className="card-one ">
            <Card.Body>
              <div className="d-flex flex-column">
                <h5 className="text-primary fw-bold">
                  Saldo Total de Clientes
                </h5>
                <ListGroup variant="flush" className="mt-3">
                  {somatorioSaldoClientes &&
                    somatorioSaldoClientes.map((somatorio, index) => (
                      <ListGroup.Item
                        key={index}
                        className="d-flex justify-content-between align-items-center py-3 px-2 border-bottom"
                      >
                        <div className="d-flex flex-column gap-1">
                          <strong className="text-secondary">
                            {somatorio.name}
                          </strong>
                          <strong
                            className={`text-end ${Number(somatorio.amount_operated) > 0
                              ? "text-success"
                              : Number(somatorio.amount_operated) == 0
                                ? "text-muted"
                                : "text-danger"
                              }`}
                          >
                            {String(somatorio.amount_operated).replace(
                              ".",
                              ","
                            )}{" "}
                            (g)
                          </strong>
                        </div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>

          {/* Somatório de saldo de fábrica */}
          <Card className="card-one ">
            <Card.Body>
              <div className="d-flex flex-column">
                <h5 className="text-primary fw-bold">Saldo Total da Fábrica</h5>
                <ListGroup variant="flush" className="mt-3">
                  {somatorioSaldoFabrica &&
                    somatorioSaldoFabrica.map((somatorio, index) =>
                      somatorio.name != null ? (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center py-3 px-2 border-bottom"
                        >
                          <div className="d-flex flex-column gap-1">
                            <strong className="text-secondary">
                              {somatorio.name}
                            </strong>
                            <strong
                              className={`text-end ${Number(somatorio.amount_operated) > 0
                                ? "text-success"
                                : Number(somatorio.amount_operated) == 0
                                  ? "text-muted"
                                  : "text-danger"
                                }`}
                            >
                              {String(somatorio.amount_operated).replace(
                                ".",
                                ","
                              )}{" "}
                              (g)
                            </strong>
                          </div>
                        </ListGroup.Item>
                      ) : null
                    )}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </>
      )}

      <Tabs
        onSelect={(e) => setModoLista(e)}
        defaultActiveKey={utp == "interno" ? "fabrica" : "cliente"}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {utp == "interno" && (
          <Tab eventKey="fabrica" title="Fábrica">
            <Card className="card-one">
              <Card.Body className="d-flex flex-column gap-3">
                <Form.Select
                  value={materialIdParaListaDeTransacao}
                  onChange={(e) =>
                    setMaterialIdParaListaDeTransacao(e.target.value)
                  }
                >
                  <option value="3">
                    <strong>AU - Ouro 18K</strong>
                  </option>
                  <option value="1">AG - Prata 950</option>
                </Form.Select>
                <Form.Group>
                  <Form.Label>Pesquisar</Form.Label>
                  <Form.Control
                    value={numeroPedido}
                    onChange={(e) =>
                      handleChangeNumeroPedido(
                        apenasNumerosInteiros(e.target.value)
                      )
                    }
                    onBlur={handleListaTransacaoDeCliente}
                    placeholder="Digite o número do pedido"
                  />
                </Form.Group>

                <Table bordered hover size="md">
                  <thead>
                    <tr>
                      <th>Transação</th>
                      <th>Pedido</th>
                      <th>Material</th>
                      <th>Quantidade</th>
                      {modoLista == "geral" && <th>Cliente</th>}
                      <th>Acumulado</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaTransacoes &&
                      listaTransacoes?.map((transaction) => (
                        <tr key={transaction.transaction_id}>
                          <td>
                            <span>
                              {transaction.amount_operated > 0 ? (
                                <FiArrowDownCircle size={24} color="green" />
                              ) : (
                                <FiArrowUpCircle size={24} color="red" />
                              )}{" "}
                              {transaction.transaction_name}
                              {transaction.transaction_type == 5 || transaction.transaction_type == 6 && <span>AQUI</span>}

                            </span>
                          </td>
                          <td>{transaction.order_code || "—"}</td>
                          <td>{transaction.material_name}</td>
                          <td
                            style={
                              transaction.amount_operated > 0
                                ? { color: "green" }
                                : { color: "red" }
                            }
                          >
                            {Number(transaction.amount_operated)}
                          </td>
                          {modoLista == "geral" && (
                            <td>{transaction.client_name}</td>
                          )}
                          <td>{Number(transaction.accumulated)}</td>
                          <td>
                            {new Date(transaction.created_at).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Tab>

        )}

        {
          utp == "interno" && <Tab
            className="card-one"
            eventKey="cliente"
            title={utp == "interno" && "Cliente"}
          >
            <div className="d-flex flex-column gap-3">
              <Card className="w-100 border-0">
                <Card.Body>
                  {utp == "interno" && (
                    <div className="d-md-flex align-items-center justify-content-between">
                      <div className="w-100">
                        {users.length > 0 ? (
                          <Select
                            className="w-100"
                            classNamePrefix="form-control"
                            placeholder="Selecione"
                            options={users}
                            onChange={(e) => {
                              setClientIdParaListaDeTransacao(e?.ID)
                              setClienteSelecionado(e)
                            }
                            }
                          />
                        ) : (
                          <p>Carregando opções...</p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-3 d-flex flex-row justify-content-between">
                    <div>
                      <strong>Saldo em conta:</strong>
                      <span className="ms-2">
                        {Number(listaTransacoes[0]?.accumulated || 0)} (g)
                      </span>
                    </div>
                    <ModalRecebimentoMetal handleListaTransacaoDeCliente={handleListaTransacaoDeCliente} handleFetch={handleFetch} cliente={clienteSelecionado} />
                  </div>
                </Card.Body>
              </Card>
              <Card className="card-one">
                <Card.Body className="d-flex flex-column gap-3">
                  <Form.Select
                    value={materialIdParaListaDeTransacao}
                    onChange={(e) =>
                      setMaterialIdParaListaDeTransacao(e.target.value)
                    }
                  >
                    <option value="3">
                      <strong>AU - Ouro 18K</strong>
                    </option>
                    <option value="1">AG - Prata 950</option>
                  </Form.Select>
                  <Form.Group>
                    <Form.Label>Pesquisar</Form.Label>
                    <Form.Control
                      value={numeroPedido}
                      onChange={(e) =>
                        handleChangeNumeroPedido(
                          apenasNumerosInteiros(e.target.value)
                        )
                      }
                      onBlur={handleListaTransacaoDeCliente}
                      placeholder="Digite o número do pedido"
                    />
                  </Form.Group>

                  <Table bordered hover size="md">
                    <thead>
                      <tr>
                        <th>Transação</th>
                        <th>Pedido</th>
                        <th>Material</th>
                        <th>Quantidade</th>
                        {modoLista == "geral" && <th>Cliente</th>}
                        {modoLista == "cliente" && <th>Acumulado</th>}
                        <th>Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaTransacoes &&
                        listaTransacoes?.map((transaction) => (
                          <tr key={transaction.transaction_id}>
                            <td>
                              <span className={"d-flex justify-content-between"}>
                                <div>
                                  {transaction.amount_operated > 0 ? (
                                    <FiArrowDownCircle size={24} color="green" />
                                  ) : (
                                    <FiArrowUpCircle size={24} color="red" />
                                  )}{" "}
                                  {transaction.transaction_name}
                                </div>

                                <div>
                                  {transaction.transaction_type == 5 || transaction.transaction_type == 6 ?
                                    <span>Peso final do pedido: {geraPesoFinalPedido(JSON.parse(transaction.polimento_weight))} (g)</span> : null}
                                </div>

                              </span>
                            </td>
                            <td>{transaction.order_code || "—"}</td>
                            <td>{transaction.material_name}</td>
                            <td
                              style={
                                transaction.amount_operated > 0
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            >
                              {Number(transaction.amount_operated)}
                            </td>
                            {modoLista == "geral" && (
                              <td>{transaction.client_name}</td>
                            )}
                            {modoLista == "cliente" && (
                              <td>{Number(transaction.accumulated)}</td>
                            )}
                            <td>
                              {new Date(transaction.created_at).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </Tab>
        }


        {utp == "interno" && (
          <Tab eventKey="emprestimo" title="Empréstimos">
            <Row className="g-3 mt-1">
              <Col xs="12">
                <Card className="card-one">
                  <Card.Body>
                    <CadastroEmprestimos handleFetch={handleFetch} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="g-3 mt-1">
              <Col xs="12">
                <Card className="card-one">
                  <Card.Body>
                    <ListaEmprestimo handleFetch={handleFetch} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
      {utp == "cliente" && <>
        <div className="d-flex flex-column gap-3">
          <Card className="w-100 card-one">
            <Card.Body>
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <strong>Saldo em conta:</strong>
                  <span className="ms-2">
                    {Number(listaTransacoes[0]?.accumulated || 0)} (g)
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="card-one">
            <Card.Body className="d-flex flex-column gap-3">
              <Form.Select
                value={materialIdParaListaDeTransacao}
                onChange={(e) => {
                  setMaterialIdParaListaDeTransacao(e.target.value)
                }
                }
              >
                <option value="3">
                  <strong>AU - Ouro 18K</strong>
                </option>
                <option value="1">AG - Prata 950</option>
              </Form.Select>
              <Form.Group>
                <Form.Label>Pesquisar</Form.Label>
                <Form.Control
                  value={numeroPedido}
                  onChange={(e) =>
                    handleChangeNumeroPedido(
                      apenasNumerosInteiros(e.target.value)
                    )
                  }
                  onBlur={handleListaTransacaoDeCliente}
                  placeholder="Digite o número do pedido"
                />
              </Form.Group>

              <Table bordered hover size="md">
                <thead>
                  <tr>
                    <th>Transação</th>
                    <th>Pedido</th>
                    <th>Material</th>
                    <th>Quantidade</th>
                    {modoLista == "geral" && <th>Cliente</th>}
                    {modoLista == "cliente" && <th>Acumulado</th>}
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {listaTransacoes &&
                    listaTransacoes?.map((transaction) => (
                      <tr key={transaction.transaction_id}>
                        <td>
                          <span className={"d-flex justify-content-between"}>
                            <div>
                              {transaction.amount_operated > 0 ? (
                                <FiArrowDownCircle size={24} color="green" />
                              ) : (
                                <FiArrowUpCircle size={24} color="red" />
                              )}{" "}
                              {transaction.transaction_name}
                            </div>

                            <div>
                              {transaction.transaction_type == 5 || transaction.transaction_type == 6 ?
                                <span>Peso final do pedido: {geraPesoFinalPedido(JSON.parse(transaction.polimento_weight))} (g)</span> : null}
                            </div>

                          </span>
                        </td>
                        <td>{transaction.order_code || "—"}</td>
                        <td>{transaction.material_name}</td>
                        <td
                          style={
                            transaction.amount_operated > 0
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {Number(transaction.amount_operated)}
                        </td>
                        {modoLista == "geral" && (
                          <td>{transaction.client_name}</td>
                        )}
                        {modoLista == "cliente" && (
                          <td>{Number(transaction.accumulated)}</td>
                        )}
                        <td>
                          {new Date(transaction.created_at).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </>}

    </div>
  );
};

export default ControleMetal;
